import { createBezier, bezierInterpolation } from "./utils";

export function polarToCartesian(centerX, centerY, radius, angleInDegrees) {
    var angleInRadians = (angleInDegrees - 90) * Math.PI / 180.0;

    return {
        x: centerX + (radius * Math.cos(angleInRadians)),
        y: centerY + (radius * Math.sin(angleInRadians))
    };
}


export function segment(center, radius, startAngle, endAngle) {
    var start = polarToCartesian(center.x, center.y, radius, endAngle);
    var end = polarToCartesian(center.x, center.y, radius, startAngle);
    let dAngle = (endAngle - startAngle);
    var largeArcFlag = dAngle < 180 ? "0" : "1";

    if(dAngle === 0) return '';

    return `M ${center.x} ${center.y}  L ${start.x} ${start.y} A ${radius} ${radius} 0 ${largeArcFlag} 0 ${end.x} ${end.y} L ${center.x} ${center.y}  z`

    // if(dAngle === 360){
    //     // circle path
    //     return `M ${(center.x - radius)}, ${center.y} a ${radius},${radius} 0 1,0 ${radius * 2},0 a ${radius},${radius} 0 1,0 -${radius*2},0`
    // }

    // if(dAngle > 0){
    //     return `M ${center.x} ${center.y}  L ${start.x} ${start.y} A ${radius} ${radius} 0 ${largeArcFlag} 0 ${end.x} ${end.y} z`
    // }

    // return ''
    //return `M ${(center.x - radius)}, ${center.y} a ${radius},${radius} 0 1,0 ${radius * 2},0 a ${radius},${radius} 0 1,0 -${radius*2},0`

}

export function rectanglePath(position, size) {
    return `M ${position.x} ${position.y} 
            L ${position.x + size.x} ${position.y} 
            L ${position.x + size.x} ${position.y + size.y} 
            L ${position.x} ${position.y + size.y} 
            z`
}



export function barBlock(position, size, value, min, max) {

    const sc = 12;
    const minV = size.y * ((sc - 1) / sc);
    const maxV = size.y * (1 / sc);
    const m = (maxV - minV) / (max - min);
    const c = minV - min * m;

    const v = value * m + c;

    return `M ${position.x} ${position.y + size.y} 
            L ${position.x} ${position.y + v} 
            L ${position.x + size.x} ${position.y + v} 
            L ${position.x + size.x} ${position.y + size.y} 
            z`
}

export function referenceLine(position, size, value, min, max) {

    const sc = 12;
    const minV = size.y * ((sc - 1) / sc);
    const maxV = size.y * (1 / sc);
    const m = (maxV - minV) / (max - min);
    const c = minV - min * m;

    const v = value * m + c;

    return `M ${position.x} ${position.y + v} 
            L ${position.x + size.x} ${position.y + v}
            `
}


export function exponentialDecay(position, size, decay) {

    const s = { x: position.x, y: position.y + size.y * 0.2 }
    const e = { x: position.x + size.x, y: position.y + size.y }
    const c1 = { x: s.x + (size.x * decay), y: s.y }
    const c2 = { x: e.x, y: e.y - decay * (size.y * 0.8) }

    return `M ${position.x} ${position.y + size.y * 0.2} 
            C ${c1.x} ${c1.y} ${c2.x} ${c2.y} ${e.x} ${e.y} 
            L ${s.x} ${e.y} 
            z`
}




export function exponentialGrowth(position, size, growth) {

    const variationY = size.y * 0.4;
    const centerY = position.y + variationY;

    const variationX = size.x * 0.4;
    const centerX = position.x + variationX;

    const s = { x: position.x, y: centerY + growth * variationY }
    const e = { x: position.x + size.x, y: centerY - growth * variationY }
    const c1 = { x: s.x + centerX, y: s.y }
    const c2 = { x: e.x, y: centerY }

    return `M ${position.x} ${position.y + size.y} 
            L ${s.x} ${s.y} 
            C ${c1.x} ${c1.y} ${c2.x} ${c2.y} ${e.x} ${e.y} 
            L ${position.x + size.x} ${position.y + size.y} 
            z`
}


export function bezierPath(position, size, A, B, min, max) {

    // Assuming that min is 1/scale from bottom and max is scale-1/scale from top

    const curvatureA = 0.1; // 0 -> 0.5
    const curvatureB = 0.6; // 0 -> 0.5
    const compressHeight = 0.8;
    const offsetY = (1 - compressHeight) * 0.5 * size.y;

    const { p1, c1, c2, p2 } = createBezier(A, B, min, max, curvatureA, curvatureB);

    // scale to size
    p1.x *= size.x
    p2.x *= size.x
    c1.x *= size.x
    c2.x *= size.x

    p1.y *= size.y * compressHeight
    p2.y *= size.y * compressHeight
    c1.y *= size.y * compressHeight
    c2.y *= size.y * compressHeight


    // translate to position
    p1.x += position.x
    p2.x += position.x
    c1.x += position.x
    c2.x += position.x

    p1.y += position.y + offsetY
    p2.y += position.y + offsetY
    c1.y += position.y + offsetY
    c2.y += position.y + offsetY


    return `M ${position.x} ${position.y + size.y} 
            L ${p1.x} ${p1.y} 
            C ${c1.x} ${c1.y} ${c2.x} ${c2.y} ${p2.x} ${p2.y} 
            L ${position.x + size.x} ${position.y + size.y} 
            z`
}






export function bezierBlocks(position, size, A, B, min, max, numBlocks) {

    // Assuming that min is 1/scale from bottom and max is scale-1/scale from top

    const curvatureA = 0.1; // 0 -> 0.5
    const curvatureB = 0.6; // 0 -> 0.5
    const compressHeight = 0.8;
    const offsetY = (1 - compressHeight) * 0.5 * size.y;

    const { p1, c1, c2, p2 } = createBezier(A, B, min, max, curvatureA, curvatureB);

    let data = ``;

    // create blocks
    const step = 1 / numBlocks;

    for (var i = 0; i < 1; i += step) {

        const p = ({ x: i, y: bezierInterpolation(i, p1.y, c1.y, c2.y, p2.y) });

        p.x = p.x * size.x;
        p.y = p.y * size.y * compressHeight

        p.x = p.x + position.x
        p.y = p.y + position.y + offsetY;

        // step rounding
        p.y = Math.round(p.y /1.5) * 1.5;

        // create data
        data += `M ${position.x + p.x} ${position.y + size.y} 
                L ${position.x + p.x} ${position.y + p.y} 
                L ${position.x + p.x + step * size.x} ${position.y + p.y } 
                L ${position.x + p.x + step * size.x} ${position.y + size.y}
    `
    }

    data += ` z`;
    return data;
}




export function arrow( position, size, direction) {

if( direction > 0){
    return `
    M ${ position.x } ${ position.y + size.y }
    L ${ position.x + size.x } ${ position.y }
    M ${ position.x + 1 / 6 * size.x } ${ position.y }
    L ${ position.x + size.x } ${ position.y }
    L ${ position.x + size.x } ${ position.y + 5 / 6 * size.y }
    `
}
if( direction < 0) {
    return `
    M ${ position.x } ${ position.y + size.y }
    L ${ position.x + size.x } ${ position.y }
    M ${ position.x } ${ position.y + 1 / 6 * size.x }
    L ${ position.x } ${ position.y + size.y }
    L ${ position.x + 5 / 6 * size.x } ${ position.y + size.y }
    `
}

// even
return `
    M ${ position.x + 1 / 6 * size.x } ${ position.y + size.y * 0.5 }
    L ${ position.x + 5 / 6 * size.x } ${ position.y + size.y * 0.5 }
    `

}



export function spline(positions) {
    // positions array of [ {x:Number, y:Number}]
    const knotsX = [];
    const knotsY = [];

    positions.forEach(element => {
        knotsX.push(element.x);
        knotsY.push(element.y);
    });


    const controlPointX = computeControlPoints(knotsX)
    const controlPointY = computeControlPoints(knotsY);

    let path = '';

    for (let i=0; i< (positions.length-1); i++){
        const p1x = positions[i].x
        const p1y = positions[i].y
        const p2x = positions[i+1].x
        const p2y = positions[i+1].y
        const cp1x = controlPointX.p1[i];
        const cp1y = controlPointY.p1[i];
        const cp2x = controlPointX.p2[i];
        const cp2y = controlPointY.p2[i];

        if( i === 0){
            path += `M ${p1x} ${p1y} C ${cp1x} ${cp1y} ${cp2x} ${cp2y} ${p2x} ${p2y} `;
        }else{
            path += `C ${cp1x} ${cp1y} ${cp2x} ${cp2y} ${p2x} ${p2y} `;
        }
        
    };
    return path;
}

export function line(positions) {
    // positions array of [ {x:Number, y:Number}]
    if (positions.length !== 2) return false;
    let p1 = positions[0]
    let p2 = positions[1]
    return `M ${p1.x} ${p1.y} L ${p2.x} ${p2.y} `;
}

// Same as spline but returns an array of bezier paths
// added so that the spline is only calculate once and can be truncated
export function splineSections(positions) {
    // positions array of [ {x:Number, y:Number}]
    const knotsX = [];
    const knotsY = [];

    positions.forEach(element => {
        knotsX.push(element.x);
        knotsY.push(element.y);
    });

    const controlPointX = computeControlPoints(knotsX)
    const controlPointY = computeControlPoints(knotsY);
    let path = [];

    for (let i=0; i< (positions.length-1); i++){
        // const p1x = positions[i].x
        // const p1y = positions[i].y
        const p2x = positions[i+1].x
        const p2y = positions[i+1].y
        const cp1x = controlPointX.p1[i];
        const cp1y = controlPointY.p1[i];
        const cp2x = controlPointX.p2[i];
        const cp2y = controlPointY.p2[i];
        path.push(`C ${cp1x} ${cp1y} ${cp2x} ${cp2y} ${p2x} ${p2y} `);        
    };

    return path;
}



const computeControlPoints = K => {
	const p1 = []
	const p2= [];
	const n = K.length-1;
	
	/*rhs vector*/
	const a= [];
	const b= [];
	const c= [];
	const r= [];
	
	/*left most segment*/
	a[0]=0;
	b[0]=2;
	c[0]=1;
	r[0] = K[0]+2*K[1];
	
	/*internal segments*/
	for (let i = 1; i < n - 1; i++)
	{
		a[i]=1;
		b[i]=4;
		c[i]=1;
		r[i] = 4 * K[i] + 2 * K[i+1];
	}
			
	/*right segment*/
	a[n-1]=2;
	b[n-1]=7;
	c[n-1]=0;
	r[n-1] = 8*K[n-1]+K[n];
	
	/*solves Ax=b with the Thomas algorithm (from Wikipedia)*/
	for (let i = 1; i < n; i++)
	{
		let m = a[i]/b[i-1];
		b[i] = b[i] - m * c[i - 1];
		r[i] = r[i] - m*r[i-1];
	}
 
	p1[n-1] = r[n-1]/b[n-1];
	for (let i = n - 2; i >= 0; --i)
		p1[i] = (r[i] - c[i] * p1[i+1]) / b[i];
		
	/*we have p1, now compute p2*/
	for (let i=0;i<n-1;i++)
		p2[i]=2*K[i+1]-p1[i+1];
	
	p2[n-1]=0.5*(K[n]+p1[n-1]);
	
	return {p1:p1, p2:p2};
}
