import 'core-js/features/array/find-index';
import 'core-js/features/array/find';
import 'core-js/features/object/assign';

import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import loadable from '@loadable/component'

import { MuiPickersUtilsProvider } from '@material-ui/pickers';

// pick a date util library
import MomentUtils from '@date-io/moment';

import {
  Box,
  useTheme,
} from '@material-ui/core';

// import 'devextreme/dist/css/dx.light.css';

import NavBar from './components/nav/NavBar';

import { CalculatorProvider, readData } from './state/calculatorState';
import { ChangeThemeProvider, readTheme } from './state/themeState';
import { LayoutProvider, readLayout, MAX_WIDTH } from './state/layoutState';

import { } from './constants';

const DashboardPage = loadable(() => import('./pages/DashboardPage'))
const PrivacyPolicyPage = loadable(() => import('./pages/PrivacyPolicyPage'))

const App = () => {
  const theme = useTheme();
  const style = {
    transition: theme.transitions.create("all", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    })
  };



  return (
    <div style={{ maxWidth:MAX_WIDTH, marginLeft:'auto', marginRight:'auto' }} >
    <ChangeThemeProvider initialState={readTheme()} >
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <CalculatorProvider initialState={readData()}>
        <LayoutProvider initialState={readLayout()}>
          <CssBaseline />
          <Box display='flex' flexDirection='column' justifyContent='center' flexGrow={1}>
            <NavBar />
            {/* <FPSStats /> */}
            <Switch>
            <Route path='/privacypolicy' render={(props) => {
                return <PrivacyPolicyPage {...props} style={style} />
              }}
              />
              
              <Route exact path='/' render={(props) => {
                return <DashboardPage {...props} style={style} />
              }}
              />

              <Redirect to="/error/" /> 
               {/* deliberate link to unresolved page will trigger a plesk 404 page */}

            </Switch>
          </Box>
        </LayoutProvider>
      </CalculatorProvider>
      </MuiPickersUtilsProvider>
    </ChangeThemeProvider>
    </div>
  )
}

export default (App);
