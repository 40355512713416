import React from 'react'
import IconButton from '@material-ui/core/IconButton';

import {
    Box,
    Typography,
    useTheme,
    makeStyles,
} from '@material-ui/core';

import WbSunnyIcon from '@material-ui/icons/WbSunny';
import Brightness2Icon from '@material-ui/icons/Brightness2';

import { useChangeTheme, TOGGLE_MODE, LIGHT_MODE} from '../../state/themeState';


  const useStyles = makeStyles(theme => ({
        button: {
            "&:hover": {
              backgroundColor: "transparent"
            }
          }
    }
));


const ThemeSwitch = () => {
    const theme = useTheme();
    const [{ themeName }, dispatch] = useChangeTheme();
    const classes = useStyles();

    const handleToggle = (e, dispatchPayload) => {
        e.stopPropagation();
        e.nativeEvent.stopImmediatePropagation();
        dispatch(dispatchPayload);
    }
    
    return (
        <div>
            <IconButton disableRipple disableTouchRipple className={classes.button} aria-label={`theme`} onClick={(e) => handleToggle(e, { type: TOGGLE_MODE } )} style={{ }}>
                {themeButtonType(themeName, theme.palette.text.primary)}
            </IconButton>
        </div>
    )
}

const themeButtonType = (themeName, col ) =>{

    let icon = <Brightness2Icon />;
    let label = "DARK"

    if( themeName === LIGHT_MODE){
        icon = <WbSunnyIcon />;
        label = "LIGHT"
    }

    return (
        <Box display="flex" flexDirection="column" alignItems="center" style={{width:34, height:35}}>
            {icon}
            <Typography variant = "h4" style={{fontSize: 12, color:col}} >{label}</Typography>
        </Box>
    )



}

export default ThemeSwitch;