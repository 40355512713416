import React, { createContext, useContext, useReducer } from 'react'
import { date2str } from 'utils/utils';
import { InitialState } from '../core/calculatorConstants';
import { Calculator } from '../core/Calculator';
import { interestPrincipalRatioData, principalValue } from '../core/CalculatorUtils'


const LOCAL_STORAGE_NAME = "state_1.5";
export const DISPATCH_UPDATE = "update";
export const DISPATCH_PROPS = "update_props";
export const DISPATCH_UPDATE_TERM = "update_term";
export const DISPATCH_UPDATE_MONTHLY = "update_monthly";
export const DISPATCH_UPDATE_LOAN = "update_loan";
export const DISPATCH_UPDATE_YIELD = "update_yield";
export const DISPATCH_UPDATE_FORECAST = "update_forecast";
export const DISPATCH_UPDATE_COSTS = "update_costs";
export const DISPATCH_INIT = "init";
export const DISPATCH_RESET = "reset";


const calculate = (state) => {

  const result = Calculator.setState(state)
    .reset()
    .validate()
    .checkLimits()
    .calculateDeposit()
    .calculateLVR()
    .calculateLMI()
    //.calculateConveyancing()
    .calculateCosts()
    .calculateInterestRateTrends()
    .calculateLoanSplit()
    // .calculateMonthlyRepayment()
    .calculatePropertyGrowthForecast()
    //.calculateInterestRateTrends()
    .calculateInterestRatesForecast()
    .calculateMortgage()
    .calculateMonthlyRepayment()
    .calculatePropertyValue()
    .calculateExtraPayments()
    .calculateYield()
    .calculateLVRTarget()
  // .calculateOngoingCosts()

  return result.state;
}


const calculateTrends = (state) => {
  //const result = Calculator.setState(state).calculateTrends();

  const result = Calculator.setState(state)
    .calculatePropertyGrowthTrends()
    .calculateInterestRateTrends();

  return result.state;
}


const calculateCosts = (state) => {
  const result = Calculator.setState(state)
    .calculateOngoingCosts()
  return result.state;
}



export const reset = () => {
  console.log("-----------RESET-------------")
  saveData({ ...InitialState });
}

export const readData = () => {
  //reset(); // reset for dev
  let data = { ...InitialState };
  if (typeof (Storage) !== "undefined") {
    try {
      data = JSON.parse(localStorage.getItem(LOCAL_STORAGE_NAME));

      const localData = localStorage.getItem(LOCAL_STORAGE_NAME);
      if (localData) {
        data = JSON.parse(localData);
      }
    }
    catch (error) { console.log(error) }

      // check for a modified data structure
    if (!data || data === undefined || data.VERSION === undefined || data.VERSION !== InitialState.VERSION) {
      data = { ...InitialState }
    }
    

    if (!data.stateTerritory) data.stateTerritory = InitialState.stateTerritory;

    // reset date
    data.startDate = date2str(new Date())
  }



  data.init = false;


  const validatedData = Calculator.setState(data).validate()

  return validatedData.state
}


export const saveData = (data) => {
  // only save the theme type not the entire object
  if (typeof (Storage) !== "undefined") {
    return localStorage.setItem(LOCAL_STORAGE_NAME, JSON.stringify(data));
  }
  return;
}


// Instantiates the Context object (no initial data)
export const CalculatorContext = createContext();

// Calculator reducer ations
export const CalculatorReducer = (state, action) => {
  let newState = {};
  //console.log("CALCULATOR STATE", action.type, action.data)

  switch (action.type) {

    case DISPATCH_RESET:
      newState = {
        ...state,
        ...InitialState,
        resetFlag: !state.resetFlag
      };

      break


    case DISPATCH_INIT:
      newState = {
        ...state,
        ipr: interestPrincipalRatioData(state.interestRate, state.loanAmount),
      }
      newState = calculateCosts(newState)

      newState = calculate(newState);
      return newState;

    case DISPATCH_PROPS:
      newState = {
        ...state,
        ...action.data,
      }
      // no calculations
      return newState


    // ------- NORMAL UPDATE ---------
    case DISPATCH_UPDATE:
    case DISPATCH_UPDATE_FORECAST:
    case DISPATCH_UPDATE_LOAN:
    case DISPATCH_UPDATE_YIELD:

      newState = {
        ...state,
        ...action.data,
      }
      break;


    case DISPATCH_UPDATE_TERM:
      // effects the forecast points
      // go through all the points and eliminate any that are now outside the term range
      // TODO: chnage start date!!!

      // interestRateTrend: [[new Date().getFullYear() + NOMINAL_TERM * 0.33, NOMINAL_MORTGAGE_RATE], [new Date().getFullYear() + NOMINAL_TERM*0.67, NOMINAL_MORTGAGE_RATE]], // inbetween rate that determines the trend control point [ year, rate ]
      newState = {
        ...state,
        ...action.data,
      }

      const recalculateTrends = calculateTrends(newState)

      newState = {
        ...newState,
        ...recalculateTrends,
      }

      //interestRateTrend
      break;



    case DISPATCH_UPDATE_MONTHLY:
      // this only applies for NON split loans!!!!

      newState = {
        ...state,
        ...action.data,
        loanAmount: principalValue(Number(state.mortgagePrimary.interestRate), Number(state.mortgagePrimary.term), Number(action.data.monthlyRepayment)),
        reverserCalculatedPrincipal: true,
      }

      //spit the new monthly between split Loan
      if (newState.splitLoan) {
        newState.mortgagePrimary.monthlyRepayment = newState.monthlyRepayment * (1 - newState.splitLoanRatio)
        newState.mortgageSecondary.monthlyRepayment = newState.monthlyRepayment * (newState.splitLoanRatio)
      } else {
        newState.mortgagePrimary.monthlyRepayment = newState.monthlyRepayment
      }
      break;

    case DISPATCH_UPDATE_COSTS:
      newState = {
        ...state,
        ...action.data,
      }
      const recalculateCosts = calculateCosts(newState)

      newState = {
        ...newState,
        ...recalculateCosts,
      }
      // dont recalculate
      saveData(newState);
      return newState


    case "":
    default:
      return
  }


  // TODO: SORT THIS OUT
  // if (  state.mortgagePrimary.interestRate !== newState.mortgagePrimary.interestRate 
  //     || (state.splitLoan && state.mortgageSecondary.interestRate !== newState.mortgageSecondary.interestRate)
  //     || newState.lpr.length <= 0) {
  //   //newState.ipr = interestPrincipalRatioData(newState.mortgagePrimary.interestRate, newState.mortgagePrimary.loanAmount)
  // }


  // ALIGN MORTGAGE values
  //newState.interestRate = newState.mortgagePrimary.interestRate
  //newState.interestRateTrend = newState.mortgagePrimary.interestRateTrend
  //newState.interestRateForecastValue = newState.mortgagePrimary.interestRateForecastValue
  //newState.term = newState.mortgagePrimary.term
  //newState.refinancePeriod = newState.mortgagePrimary.refinancePeriod
  //newState.initialPeriod = newState.mortgagePrimary.initialPeriod

  // force the current date
  newState.start = date2str(new Date());
  newState = calculate(newState);
  saveData(newState);

  return newState
};



// Wrapper for the Provider injection
export const CalculatorProvider = ({ initialState, children }) => {

  return (
    <CalculatorContext.Provider value={useReducer(CalculatorReducer, { ...initialState })}>
      {children}
    </CalculatorContext.Provider>
  )
};


// a custom hook function to access your state/reducer in any component
// It returns [state, dispatch] array, that is passed as a value to our Provider.
// const [{ superannuationRate, additionalSuper, deductions, nonResident, backpacker, noTaxFree, HELP, withhold }, dispatch] = useCalculator();

// NOTE: A component calling useContext will always re-render when the context value changes. If re-rendering the component is expensive, you can optimize it by using memoization.
export const useCalculator = () => useContext(CalculatorContext);