import React from 'react'
import {
    Box,
} from '@material-ui/core'

import ThemeSwitch from './ThemeSwitch';
import Reset from './Reset';
import Logo from './Logo';



const NavBar = () => {

    return (
        <Box display="flex" justifyContent="space-between" alignItems="center">
            <Logo />
            <Box display="flex" >
            <Reset/>    
            <ThemeSwitch/>
            </Box>
    </Box>
    )
}

export default NavBar;