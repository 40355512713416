export const NBSP = '\u00A0'

export const MAX_TERM = 40;

export const EMAIL_ADDRESS = 'hello@mortgage.monster';
export const MAILTO_ADDRESS = `mailto:${EMAIL_ADDRESS}`

export const SOURCE_INTEREST_RATES = `https://www.rba.gov.au/statistics/interest-rates/`
export const SOURCE_INTEREST_RATES_TITLE = 'Source: Reserve Bank of Australia (New owner occupied variable loans)'


export const SOURCE_PRICE_INDEX = `https://www.abs.gov.au/statistics/economy/price-indexes-and-inflation/residential-property-price-indexes-eight-capital-cities`
export const SOURCE_PRICE_INDEX_TITLE = 'Source: ABS, Residential Property Price Indexes'

export const LMI_TOOLTIP = 'Your loan may attract a premuim that is added to your loan to protect the bank against any loss that may occurr if you are unable to replay your loan';
export const TOOLTIP_DELAY = 1000;


export const MONTH_NAMES = ["January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
];

