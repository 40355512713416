import React from 'react'
import { IconButton, makeStyles } from '@material-ui/core'
import { useChangeTheme, DARK_MODE} from '../../state/themeState';

import darkLogo from '../../assets/img/Logo_dark.svg';
import lightLogo from '../../assets/img/Logo_light.svg';

const useStyles = makeStyles(() => ({
    button: {
        "&:hover": {
          backgroundColor: "transparent"
        }
      }
}
));

const Logo = () => {
    const [{ themeName }] = useChangeTheme();
    const classes = useStyles();


    return (
        <IconButton disableRipple disableTouchRipple aria-label="Switch theme" href="/" className={classes.button}  style={{ textAlign: 'left', height: 89, width: 220 }} >
            <img width={220} height={89} src={themeName === DARK_MODE ? darkLogo : lightLogo } alt={themeName === DARK_MODE ? "dark mode" : "light mode" }/>
        </IconButton>
    );
}

export default Logo;
