import { createTheme } from '@material-ui/core/styles';

const fontHeading = {
    fontFamily: [
        'Roboto',
        'Arial',
        'sans-serif',
    ],
    fontWeight:500,
    // textShadow: '2px 1px 1px rgba(0,0,0,0.1)',
    textShadow: '-1px -1px 1px rgba(255,255,255,0.25)',
}


const fontBody = {
    fontWeight:400,
    fontFamily: [
        'Roboto',
        'Arial',
        'sans-serif',
    ],
}

const palette = {
    primary: {
        light: '#88BEE0',
        // main: '#669BBC',
        // dark: "#176184", //'#ee9337',
        main: '#176184',
        dark: "#669BBC", //'#ee9337',
        contrast:"#292929", 
    },
    secondary: {
        light: '#BFEDDD',
        // main: '#7ed296',//'#94D2BD',
        // dark: '#73AF9A',
        main: '#73AF9A',//'#94D2BD',
        dark: '#7ed296',
        contrast:"#292929",
    },
    tertiary: {
        light: '#FAD584',
        // main: '#EE9B00',
        // dark: '#E08301', //'#03324B"
        main: '#E08301',
        dark: '#EE9B00', //'#03324B"
        contrast:"#292929",
    },

    background: {
        // paper: 'rgb(250, 250, 250)',// '#FFFFFF',
        paper: '#F8F8F8',
        default: '#FFFFFF',
        overlay: '#FFFFFF',
        outline: '#999999',

        darkTint: '#aaaaaa',


        highContrast: '#000000',
    }
}


// setup themes
export default createTheme({

    breakpoints: {
        values: {
          xs: 0,
          sm: 740, // custom
          md: 900,
          lg: 1200,
          xl: 1536,
        },
      },

    props:{
        MuiButtonBase: {
            TouchRippleProps: {
                 style: {color: palette.primary.main}
                
            }
        },
    },

    typography: {
        
        button: {
            fontWeight: '700',
            ...fontHeading,
            fontSize: 14,
          },

          h1:{
            ...fontHeading,
            color: palette.primary.contrast,
            fontSize: 24,
            fontWeight: 500,
            lineHeight: 1.25,
            letterSpacing: "-0.75px",
            textShadow: "1px 1px 1px rgba(0,0,0,0.1)",
        },
        h2:{
            ...fontHeading,
            fontSize: 14,
            fontWeight: 500,
            letterSpacing: "-0.5px"
        },
        h3:{
            ...fontHeading,
            color: palette.primary.contrast,
            fontSize: 16,
            fontWeight:600,
            letterSpacing: "-0.5px"
        },


        h4:{
            ...fontBody,
            color: palette.primary.dark,
            fontWeight: 700,
            fontSize: 10,
            lineHeight: 0.95,
            letterSpacing: '-0.25px',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            textShadow: '2px 2px 2px rgba(0,0,0,0.1)',
        },
        h5:{
            ...fontHeading,
            color: palette.primary.contrast,
            fontSize: 14,
            fontWeight:500,
            letterSpacing: "-0.5px"
        },

        h6:{
            ...fontHeading,
                color: palette.primary.dark,
                fontWeight: 700,
                fontSize: 15,
                lineHeight: 0.95,
                letterSpacing: '-0.25px',
                // overflow: 'hidden',
                textAlign:"center",
                textTransform: "uppercase",
        },



        body1:{
            ...fontBody,
            fontSize: 11,
            fontWeight: 500,
            letterSpacing: "-0.1px",
            color: palette.primary.contrast,
            // opacity:0.75,
        },
        body2:{
            ...fontBody,
            fontSize: 11,
            fontWeight: 400,
            letterSpacing: "-0.1px",
            color: "#666666",
        },
        body3:{
            ...fontBody,
            fontSize: 14,
            fontWeight: 400,
            letterSpacing: "-0.25px",
        },

    },
    palette: {
        ...palette,

        type: 'light',
        grey:{
            // "300":"#2f2f2f", // lighter
            "900":"#999999", // darker
        },

        text: {
            primary:"#333333",
            secondary:"#FAFAFA",
            link:"#c68712",
            alert:palette.primary.main,
        },
        palette2:`${[palette.primary.main, palette.secondary.main]}`,
        palette3:`${[palette.primary.main, palette.primary.light, palette.secondary.main]}`,
        palette4:`${[palette.primary.main, palette.primary.light, palette.secondary.main, palette.secondary.light]}`,


       
    },
    transitions: {
        duration: {
            standard: 500,
        }
    }
});
