import { createTheme } from '@material-ui/core/styles';


const fontHeading = {
    fontFamily: [
       // 'Ubuntu',
       // 'Noto Sans JP',
       'Roboto',
        'Arial',
        'sans-serif',
    ],
    userSelect: 'none',
    textShadow: '1px 1px 1px rgba(0,0,0,0.4)',
}


const fontBody = {
    fontFamily: [
       // 'Noto Sans JP',
       // 'Ubuntu',
       'Roboto',
        'Arial',
        'sans-serif',
    ],
    userSelect: 'none',
}


const palette = {
primary: {
    light: '#FAD584',
    main: '#F6B72B',
    mainTransparent: 'rgba(246, 182, 44, 0.3)',
    dark: '#E08301', //'#03324B"
    contrast:"#E0E0E0",
},
secondary: {
    light: '#93ccb8',
    main: '#7FB09F',
    dark: "#5a7d71",
    contrast:"#E0E0E0",
},

tertiary: {
    light: '#F7403A',
    main: '#F7403A',
    dark: '#F7403A',
    contrast:"#E0E0E0",
},

background: {
    default: '#272B2F',
    paper: '#21252A',
    overlay: '#272B2F',
    outline: '#aaaaaa',

    darkTint: '#111111',
    
    highContrast: '#ffffff',

},
contrastThreshold: 3,
tonalOffset: 0.2,
error:{
    light: '#ff9800',
    main: '#ff9800',
    dark: '#ff9800',
},

}


// setup themes
export default createTheme({

    components: {
        // Name of the component
        MuiDialogTitle: {
          styleOverrides: {
            // Name of the slot
            root: {
              // Some CSS
              fontSize: '3rem',
            },
          },
        },
      },


    breakpoints: {
        values: {
          xs: 0,
          sm: 740, // custom
          md: 900,
          lg: 1200,
          xl: 1536,
        },
      },

    shape: {
        borderRadius: 3
    },

    props:{
        MuiButtonBase: {
            TouchRippleProps: {
                 style: {color: palette.primary.main}
                
            }
        },
    },

    palette: {
        ...palette,

        type: 'dark',

        grey:{
            "300":"#2f2f2f",
            "900":"#000000",
        },

        text: {
            primary:"#FAFAFA",
            secondary:"#121212",
            link:"#b1ff35",
            alert:palette.primary.light,
        },
    },

    typography: {
        root:{
            opacity:1.0,
        },
        button: {
            ...fontHeading,
            fontWeight: '700',
            fontSize: 14,
          },
        h1:{
            ...fontHeading,
            color: palette.primary.contrast,
            fontSize: 24,
            fontWeight: 500,
            lineHeight: 1.25,
            letterSpacing: "-0.5px",
            textShadow: '1px 1px 1px rgba(0,0,0,0.4)',
        },
        h2:{
            ...fontHeading,
            fontSize: 14,
            fontWeight: 400,
            letterSpacing: "-0.25px"
        },
        h3:{
            ...fontHeading,
            color: palette.primary.contrast,
            fontSize: 17,
            fontWeight:750,
            letterSpacing: "-0.25px"
        },


        h4:{
            ...fontBody,
            color: palette.primary.light,
            fontWeight: 700,
            fontSize: 10,
            lineHeight: 0.95,
            letterSpacing: '-0.5px',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            textShadow: '2px 2px 2px rgba(0,0,0,0.1)',
        },
        h5:{
            ...fontHeading,
            color: palette.primary.contrast,
            fontSize: 14,
            fontWeight:500,
            letterSpacing: "-0.5px"
        },
        h6:{
            ...fontHeading,
                color: palette.primary.light,
                fontWeight: 700,
                fontSize: 15,
                lineHeight: 0.95,
                letterSpacing: '-0.25px',
                // overflow: 'hidden',
                textAlign:"center",
                textTransform: "uppercase",

        },

        
        body1:{
            ...fontBody,
            fontSize: 12,
            fontWeight: 500,
            letterSpacing: "-0.1px",
            color: palette.primary.contrast,
            // opacity:0.75,
        },
        body2:{
            ...fontBody,
            fontSize: 12,
            fontWeight: 400,
            letterSpacing: "-0.1px",
            color: "#999999",
        },
        body3:{
            ...fontBody,
            fontSize: 14,
            fontWeight: 400,
            letterSpacing: "-0.25px",
        },
    },

    transitions: {
        duration: {
            standard: 500,
        }
    }
});