import React, { createContext, useContext, useReducer } from 'react'
import { ThemeProvider } from '@material-ui/core/styles';

import DarkTheme from '../styles/DarkTheme'
import LightTheme from '../styles/LightTheme'

export const DARK_MODE = "dark";
export const LIGHT_MODE = "light";
export const TOGGLE_MODE = "toggle";
export const SET_THEME = "setTheme";


// associate theme names with theme data
const THEMES = [
    DarkTheme,
    LightTheme,
];

const LOCAL_STORAGE_NAME = "theme_1.0";

const INITIAL_STATE = {themeName:DARK_MODE, theme:null};



// Return the correct theme data for a given theme name
export const getThemeData = (themeName) => {
    const matchedTheme = THEMES.find(theme => theme.palette.type === themeName);
    return matchedTheme ? matchedTheme : THEMES[0];
}

export const readLayout = () => {
    let data = INITIAL_STATE;
    if (typeof(Storage) !== "undefined") {
        try { 
            const localData = localStorage.getItem(LOCAL_STORAGE_NAME);
            if (localData) {
                data = JSON.parse(localData);
            }
        }
        catch (error) { console.log(error) }
    }
    return data;
}


export const saveTheme = (state) => {
    // only save the theme type not the entire object
    if (typeof(Storage) !== "undefined") {
        const { themeName } = state;
        return localStorage.setItem(LOCAL_STORAGE_NAME, JSON.stringify({ themeName } ));
    }
}


export const readTheme = () => {
    // only save the theme type not the entire object
    let data = INITIAL_STATE;
    if (typeof(Storage) !== "undefined") {
        try { data = JSON.parse(localStorage.getItem(LOCAL_STORAGE_NAME)) }
        catch (error) { console.log("ERROR", localStorage.getItem(LOCAL_STORAGE_NAME))  }
    }

    data = {...INITIAL_STATE, ...data}
    
    return {...data, theme:getThemeData(data.themeName)};
}

// export const saveTheme = (themeName) => {
//     // only save the theme type not the entire object
//     return localStorage.setItem(LOCAL_STORAGE_NAME, themeName);
// }

const toggleTheme = (currentTheme) => {
    return currentTheme === DARK_MODE ? LIGHT_MODE : DARK_MODE;
}


export const ThemeContext = createContext();

// Calculator reducer ations
export const ChangeThemeReducer = (state, action) => {
    // action will be DARK_MODE or LIGHT_NAME
    let newState = {};
    switch (action.type) {
        case TOGGLE_MODE:
            newState.themeName = toggleTheme(state.themeName);
            saveTheme(newState);
            break;

        case SET_THEME:
        case DARK_MODE:
        case LIGHT_MODE:
            newState.themeName = action.theme;
            saveTheme(newState);
            break;
        default:
            break;
    }

    newState.theme = getThemeData(newState.themeName);
    return { ...state, ...newState };
};


// Wrapper for the Provider injection
// includes the Material-ui ThemeProvicer as a child
export const ChangeThemeProvider = ({ initialState, children }) => {
    const reducer = useReducer(ChangeThemeReducer, initialState);
    return (
        <ThemeContext.Provider value={reducer} >
            <ThemeProvider theme={reducer[0].theme}>
                {children}
            </ThemeProvider>
        </ThemeContext.Provider>
    )
};

export const useChangeTheme = () => useContext(ThemeContext);
