import React from 'react'
import IconButton from '@material-ui/core/IconButton';

import {
    Box,
    Typography,
    useTheme,
    makeStyles,
} from '@material-ui/core';

import RotateLeftIcon from '@material-ui/icons/RotateLeft';

import { useCalculator, DISPATCH_RESET } from '../../state/calculatorState';
import { useLayout, DISPATCH_RESET_LAYOUT } from '../../state/layoutState';


const useStyles = makeStyles(theme => ({
    button: {
        "&:hover": {
            backgroundColor: "transparent"
        }
    }
}
));


const Reset = () => {
    const theme = useTheme();
    const [, dispatch] = useCalculator();
    const [, dispatchLayout] = useLayout();
    const classes = useStyles();

    const handleReset = (e) => {
        e.stopPropagation();
        e.nativeEvent.stopImmediatePropagation();
        
        dispatch({type:DISPATCH_RESET});
        dispatchLayout({type:DISPATCH_RESET_LAYOUT});
    }


    return (
        <div>
            <IconButton disableRipple disableTouchRipple className={classes.button} aria-label={`Reset`} onClick={(e) => handleReset(e)} style={{}}>
                <Box display="flex" flexDirection="column" alignItems="center" style={{width:34, height:35}}>
                    <RotateLeftIcon/>
                    <Typography variant="h4" style={{ fontSize: 12, color: theme.palette.text.primary }} >RESET</Typography>
                </Box>
            </IconButton>
        </div>
    )
}


export default Reset;